import { HandleLock } from '@libs/lila-auth';
import log from 'loglevel';
import Vue from 'vue';
import { Route } from 'vue-router';
import insights from '@mixins/insights';
import Attributes from '@mixins/attributes';
import facebook from '@mixins/facebook';
import { state } from '@store/main.store';
import { ExtComponent } from '@libs/lila-component';

export default (settings: Record<string, any>) => {

  log.info('%c[GFM]%cMOUNTED', 'background: #00505a; color: #CCC;', 'padding: 10px');

  if (state.settings?.insights) {

    Vue.use(insights, state.settings.insights);

  }

  if (settings.store.state.settings?.facebook) {

    facebook(settings.store.state.settings.facebook);

  }

  settings.router.beforeEach((to: Route, from: Route, next) => {

    console.log(state.Navigation.status);

    if (state.Navigation.status !== 'done') return next();

    if (!state.Navigation.init && state.Navigation.status === 'done') {

      settings.store.commit('Navigation/init');

    }

    settings.store.commit('Navigation/fromComponent', from.name);
    settings.store.commit('Navigation/targetComponent', to.name);

    if (to.name === from.name && to.meta.contentUpdateMenuAnimation === false) {

      settings.store.commit('menuAnimation', false);

    } else settings.store.commit('menuAnimation', true);


    const isContentUpdate = !!(to.name === from.name);

    if (state.Navigation.contentUpdate !== isContentUpdate) {

      settings.store.commit('Navigation/contentUpdate', isContentUpdate);

    }

    next();

  });

  settings.router.beforeResolve(async (to: Route, from: Route, next) => {

    // wait 500ms until the menu is closed to start the navigation
    if (state.menuIsOpen) {

      await new Promise<void>((resolve) => {

        setTimeout(() => resolve(), 500);

      });

    }


    let preloadedData: unknown;
    let preloadError: unknown;
    const matched: any = [...settings.router.getMatchedComponents(to)].pop();
    const methods = matched?.options?.methods as ExtComponent;

    return settings.store.dispatch('Navigation/start')
      .then(() => {

        if (to.name !== from.name) settings.store.dispatch('Navigation/loading');

        if (!HandleLock(to, from, next)) return false;

        if (typeof methods?.preloadDataPre === 'function') {

          return methods.preloadDataPre(to.params, to.query, settings.store, to);

        }

        if (typeof methods?.asyncData === 'function') {

          return methods.asyncData(to.params, to.query, settings.store, to);

        }

        return Promise.resolve();

      })
      .then((data) => {

        preloadedData = data;
        return settings.store.dispatch('Navigation/resolving');

      })
      .then(() => {

        if (typeof methods?.preloadDataPost === 'function') {

          return methods.preloadDataPost(preloadedData, to.params, to.query, settings.store, to);

        }

        return Promise.resolve();

      })
      .then(() => {

        settings.store.dispatch('Navigation/done');
        next();
        return true;

      })
      .catch((error) => {

        log.error(error);
        preloadError = error;


        return settings.store.dispatch('Navigation/resolving');


      })
      .then(() => {

        if (typeof methods?.preloadDataError === 'function') {

          return methods.preloadDataError(preloadError, to.params, to.query, settings.store, to);

        }

        return Promise.resolve();

      })
      .then(() => {

        settings.store.dispatch('Navigation/done');
        next();

        return true;

      })
      .catch((e) => {

        log.error(e);

      });

  });

  settings.router.afterEach((to, from) => {

    if (state.Navigation.animationTime === 0 && state.Navigation.init) {

      settings.store.commit('Navigation/enableAnimation');

    }

    const menuIsHidden = to.meta.menuHidden === true;

    if (state.Navigation.menuIsHidden !== menuIsHidden) {

      settings.store.commit('menuHidden', menuIsHidden);

    }

  });

  settings.router.onReady(() => {

    log.info('%c[GFM]%cROUTER READY', 'background: #00505a; color: #CCC;', 'padding: 10px');
    setTimeout(() => {

      document.body.classList.add('inited');

    }, 550);

    window.dispatchEvent(new Event('hydrated'));

  });

  Attributes();

  const APP = new Vue(settings);

  APP.$mount('#app');

};

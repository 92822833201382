import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { VueConstructor } from 'vue/types/umd';

const plugin = {
  install: (vue: VueConstructor, settings: {key: string, exclude?: string[]}): void => {

    const insights = new ApplicationInsights({
      config: {
        instrumentationKey: settings.key,
        enableAutoRouteTracking: true,
        enableDebug: true,
        enableCorsCorrelation: true,
        correlationHeaderExcludedDomains: settings.exclude,
      },
    });

    insights.loadAppInsights();

    vue.prototype.$insights = insights;

  },
};

export default plugin;

import { RedirectLoginOptions, Auth0Client } from '@auth0/auth0-spa-js';
import { Route } from 'vue-router';
import store, { state } from '@store/main.store';
import log from 'loglevel';
import { Me } from '@interfaces/Me.interface';

class Auth {

  authenticated = false;

  inProgess = false;

  // storage: Storage;

  token: string;

  origin: string;

  auth0: Auth0Client;

  dev: boolean;

  constructor() {

    // this.storage = window.localStorage;
    this.origin = window.location.origin;

  }

  // eslint-disable-next-line camelcase
  async init(dev: boolean, auth0?: {domain: string, client_id: string, audience: string}) {

    log.info('%c[GFM]%cAUTH INIT', 'background: #00505a; color: #CCC;', 'padding: 10px');

    let isAuth: boolean;

    if (dev) {

      return this.setDev();

    }

    this.auth0 = new Auth0Client(
      {
        ...auth0,
        useRefreshTokens: true,
        cacheLocation: 'localstorage',
        redirect_uri: `${this.origin}/login/callback`,
        scope: 'openid profile',
      }
      || {
        domain: 'greenforme.eu.auth0.com',
        client_id: 'TM0WKAiE5GeeDGB6rRrmGHcDg5l3IEab',
        redirect_uri: `${this.origin}/login/callback`,
        scope: 'openid profile',
        audience: 'https://konfigurator.greenforme.de',
        useRefreshTokens: true,
        cacheLocation: 'localstorage',
      },
    );

    try {

      isAuth = await this.checkAuth();

    } catch (e) {

      console.error(e.message);

      if (e.message === 'USER_NOT_FOUND') {

        this.auth0.logout({ returnTo: `${window.location.protocol}//${window.location.host}` });

      }

    }

    log.info('%c[GFM]%cAUTH INITED', 'background: #00505a; color: #CCC;', 'padding: 10px');
    return isAuth;


  }

  setDev() {

    // const { localStorage } = window;

    // localStorage.setItem('token', 'DEVTOKEN');
    this.dev = true;

  }

  checkRoute(to: Route): Promise<void> {

    return new Promise((resolve: (user?: any) => void, reject: () => void) => {

      if (state.authToken) return resolve();

      if (this.dev) {

        store.commit('setUser', state.settings.devUser);
        store.commit('setAuthToken', 'DEVTOKEN');

        return resolve();

      }

      return this.auth0.getTokenSilently()
        .then((token) => {

          store.commit('setAuthToken', token);
          return store.dispatch('getMe');

        })
        .then(() => {

          resolve();

          return true;

        })
        .catch(() => {

          if (to.meta.authRequired) {

            return reject();

          }

          resolve();
          return false;

        });

    });

  }

  async checkAuth(): Promise<boolean> {

    let token: string;
    let userData: any;

    this.inProgess = true;

    if (state.authToken) return true;

    if (this.dev) {

      store.commit('setUser', state.settings.devUser);
      store.commit('setAuthToken', 'DEVTOKEN');

      return true;

    }

    const isAuth = await this.auth0.isAuthenticated();

    if (!isAuth) return false;

    try {

      token = await this.auth0.getTokenSilently({ scope: 'openid profile' });

    } catch (error) {

      log.error(error);
      throw new Error('could not get token');

    }

    store.commit('setAuthToken', token);

    try {

      userData = await store.dispatch('getMe');

    } catch (error) {

      throw new Error('USER_NOT_FOUND');

    }

    await store.dispatch('getMembership');

    store.commit('Settings/clean');

    if (userData.settings) {

      store.commit('Settings/update', userData.settings);

    }

    log.info('%c[GFM]%cAUTH DONE', 'background: #00505a; color: #CCC;', 'padding: 10px');
    return true;

  }

  async getNewToken() {

    let token: string;

    console.debug('token.get.new');

    try {

      token = await this.auth0.getTokenSilently({ ignoreCache: true, scope: 'openid email' });

    } catch (error) {

      log.error(error);

    }

    store.commit('setAuthToken', token);
    return token;

  }

  // eslint-disable-next-line class-methods-use-this
  isUser(user: Me) {

    console.log(!!user._id, user._id);

    return !!user._id;

  }

  authorize(options?: RedirectLoginOptions) {

    this.auth0.loginWithRedirect(options);

  }

  register(options?: RedirectLoginOptions) {

    this.auth0.loginWithRedirect({ ...options, mode: 'signUp' });

  }

  async handle() {

    const returnState = await this.auth0.handleRedirectCallback();

    await this.checkAuth();

    return returnState;

  }

  logout(url?: string) {

    store.commit('setAuthToken', null);
    this.auth0.logout({ returnTo: `${window.location.protocol}//${window.location.host}` });

  }

  // eslint-disable-next-line class-methods-use-this
  getAuthHeader() {

    return state.authToken ? { Authorization: `Bearer ${state.authToken}` } : {};

  }

}

const auth = new Auth();
const Guard = (to: Route, from: Route, next: (to?: any) => void) => {

  auth.checkAuth()
    .then(() => next())
    .catch((e) => {

      log.error('auth error', e);
      next('/login');

    });

};
const ConceptGuardv2 = async (to: Route, from: Route, next: (to?: any) => void) => {

  let concept;

  try {

    await auth.checkAuth();

  } catch (error) {

    console.error('CONCEPT_GUARD_ERROR', error);
    next({ name: 'content-public', params: { id: '404' } });

  }

  if (to.meta.concept && state.concept?._id !== to.params.id) {

    concept = await store.dispatch('getConcept', to.params.id);

  }

  if (concept?.concept === false) {

    return next({ name: 'share_concept_overview', params: { id: concept.shareId } });

  }

  next();


};
const ShareConceptGuardv2 = async (to: Route, from: Route, next: () => void) => {

  if (to.meta.concept && state.concept?._id !== to.params.id) {

    await store.dispatch('Shared/getConcept', to.params.id);

  }

  next();


};
const ConceptGuard = (to: Route, from: Route, next: (to?: any) => void) => {

  auth.checkRoute(to)
    .then(() => {

      if (to.meta.concept && state.concept?._id !== to.params.id) {

        return store.dispatch('getConcept', to.params.id);

      }

      return new Promise((resolve) => resolve(null));

    })
    .then((concept) => {

      if (concept.concept === false) {

        return next({ name: 'share_concept_overview', params: { id: concept.shareId } });

      }

      return next();

    })
    .catch((e) => {

      console.error('concept guard error', e);
      next({ name: 'content-public', params: { id: '404' } });

    });

};
const SignupGuard = async (to: Route, from: Route, next: (to?: string | boolean) => void) => {

  if (state.user?._id) return next();

  if (!state.user?._id && to.params.signup) {

    try {

      await auth.checkAuth();

    } catch (error) {

      console.error(error);

    }

    if (!state.user?._id) {

      return auth.register({ appState: { route: { name: to.name, params: to.params } } });

    }

  }

  next();

};
const HandleLock = (to: Route, from: Route, next: ({ name: string }) => void): boolean => {

  if (['challenge-content', 'login-callback', 'logout'].includes(to.name)) return true;

  if (state.lock && to.name !== state.lock) {

    if (from.name !== state.lock) {

      next({ name: state.lock });

      return false;

    }

    return false;

  }

  return true;

};

export default auth;
export {
  Guard,
  ConceptGuard,
  ConceptGuardv2,
  ShareConceptGuardv2,
  SignupGuard,
  HandleLock,
};

import ModuleGeneric from '@interfaces/ModuleGeneric.interface';

const getReplacement = (module: ModuleGeneric, data: any): ModuleGeneric => {

  if (module.hint === 'concept') {

    return {
      type: 'concept-module',
      async: true,
      mode: 'concept',
      conceptId: module.hintData,
      preview: true,
      id: module.hint,
    };

  }

  if (module.hint === 'concept-list') {

    return {
      type: 'concept-list-module',
      async: true,
      amount: +module.hintData ?? 3,
      more: true,
      id: module.hint,
    };

  }

  if (module.hint === 'shared') {

    return {
      type: 'concept-module',
      async: true,
      mode: 'share',
      conceptId: module.hintData,
      preview: true,
      id: module.hint,
    };

  }

  if (module.hint === 'shopping') {

    return {
      type: 'plants-list-module',
      id: module.hint,
      plants: data.data.plants,
    };

  }

  if (module.hint === 'activate') {

    return {
      type: 'activate-module',
      id: module.hint,
      description: {},
    };

  }

  if (module.hint === 'membership') {

    return {
      type: 'membership-module',
      id: module.hint,
      description: {},
    };

  }

  if (module.hint === 'membership-content') {

    return {
      type: 'membership-content-module',
      id: module.hint,
      description: {},
    };

  }

  return module;

};
const replaceModules = (modules: ModuleGeneric[], data: any) => {

  if (modules?.find((single) => single.type === 'placeholder-module')) {

    modules.forEach((single, index) => {

      if (single.type === 'placeholder-module') {

        modules[index] = getReplacement(single, data);

      }

    });

  }

  return modules;

};
const replace = (data: any, additionalData?: any) => {

  if (data.modules) {

    data.moddules = replaceModules(data.modules, additionalData);

  }

  return data;

};

export { replace, replaceModules };

import dayjs from 'dayjs';
import 'dayjs/locale/de';
import relativeTime from 'dayjs/plugin/relativeTime';

import vue from 'vue';

dayjs.extend(relativeTime);

dayjs.locale('de');

vue.filter('moment', (value: Date, format: string, fromNow?: boolean) => {


  if (!value) return '';

  return !fromNow ? dayjs(value).format(format || 'DD.MM.YYYY HH:mm') : dayjs(value).fromNow();

});

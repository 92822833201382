import ModuleGeneric from '@interfaces/ModuleGeneric.interface';

const filterModules = (modules: ModuleGeneric[], position: 'top' | 'content' | 'bottom', source?: string) => {

  const filteredModules: ModuleGeneric[] = [];

  modules?.forEach((single) => {

    if (source) single.source = source;

    if (position === 'content') {

      if (!single.position || single.position === 'content') {

        filteredModules.push(single);

      }

    } else if (single.position === position) {

      filteredModules.push(single);

    }

  });

  return filteredModules;

};

export default (
  baseModules: ModuleGeneric[],
  position: 'top' | 'content' | 'bottom',
  layoutModules?: ModuleGeneric[],
  source?: string,
): ModuleGeneric[] => {

  const modules = layoutModules ? filterModules(layoutModules, position, source) : [];

  const mergeModules = filterModules(baseModules, position);

  const prepend = modules?.filter((single) => single.layout === 'first' || !single.layout);

  const append = modules?.filter((single) => single.layout === 'last');

  if (prepend) mergeModules.unshift(...prepend);

  if (append) mergeModules.push(...append);

  return mergeModules;

};

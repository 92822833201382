import ChildData from '@interfaces/ChildData.interface';
import Content from '@interfaces/Content.interface';
import ContentWithPositions from '@interfaces/ContentWithPositions.interface';
import filterMergeModules from './filterMergeModules';
import hardCopy from './hardCopy';

const addNamespace = (modules: Content['modules'], namespace = 'lila') => {

  modules?.forEach((single) => {

    // check if already in this namespace
    if (single.type?.match(new RegExp(`${namespace}-`))) return;
    single.type = `${namespace}-${single.type}`;

    // namespace also child modules of this module
    if (single.modules) addNamespace(single.modules, namespace);

  });

};
const generateDataWithContent = (genericDataCache: GenericData) => {

  const generatedCache: GenericDataWithContent = {} as GenericDataWithContent;

  Object.keys(genericDataCache).forEach((key) => {

    if (key === 'data') return;

    const idArray: string[] = genericDataCache[key] || [];

    idArray.forEach((single) => {

      if (!generatedCache[key]) generatedCache[key] = {};

      generatedCache[key][single] = genericDataCache.data.find((singleData) => singleData._id?.toString() === single);

    });

  });

  return generatedCache;

};
const distributeChildData = (modules: Content['modules'], childData: ChildData) => {

  modules.forEach((singleModule) => {

    if (!singleModule.childData?.index) return;

    const newData: Record<string, Content> = {};

    singleModule.childData.index?.forEach((single) => {

      newData[single] = childData?.data[single];

    });

    singleModule.childData.data = newData;

  });

};
const distributeGenericData = (modules: Content['modules'], genericData: GenericDataWithContent) => {

  let flattenedGenericData: Record<string, Content> = {};

  if (!genericData) return;

  Object.values(genericData).forEach((single) => {

    flattenedGenericData = { ...flattenedGenericData, ...single };

  });

  modules.forEach((singleModule) => {

    if (!singleModule.genericData) return;

    Object.values(singleModule.genericData)
      .filter((single) => Array.isArray(single))
      .forEach((idArray: string[]) => {

        idArray.forEach((single) => {

          if (!singleModule.genericData.data) singleModule.genericData.data = {};
          singleModule.genericData.data[single] = flattenedGenericData[single];

        });

      });

  });

};
const createIndex = (modules: Content['modules']) => {

  const index: ModuleIndexOptions[] = [];

  modules.forEach((single) => {

    if (single.index?.title && single.index?.anchor) index.push({ ...single.index, type: single.type, uuid: single.uuid });

  });

  return index;

};

export default (content: Partial<Content>, layout?: Content, namespace: string = 'lila') => {

  const namespacedContent: Partial<Content> = hardCopy(content);
  let namespacedLayout: Partial<Content> = {};

  if (layout) {

    namespacedLayout = hardCopy(layout);

  }

  // if (namespacedContent?.modules) addNamespace(namespacedContent?.modules, namespace);

  // if (namespacedLayout?.modules) addNamespace(namespacedLayout?.modules, namespace);

  if (namespacedContent?.modules && content.childData) {

    distributeChildData(namespacedContent.modules, content.childData);

  }

  const combinedContent: ContentWithPositions = {
    id: content?.id,
    settings: namespacedContent?.settings || {},
    top: namespacedContent?.modules ? filterMergeModules(namespacedContent?.modules, 'top', namespacedLayout?.modules) : [],
    content: namespacedContent?.modules ? filterMergeModules(namespacedContent?.modules, 'content', namespacedLayout?.modules) : [],
    bottom: namespacedContent?.modules ? filterMergeModules(namespacedContent?.modules, 'bottom', namespacedLayout?.modules) : [],
    additional: {
      index: namespacedContent?.modules ? createIndex(namespacedContent?.modules) : [],
    },
  };

  return combinedContent;

};

export {
  distributeChildData,
  distributeGenericData,
  generateDataWithContent,
};

import Partition from '@interfaces/partition.interface';
import Plant from '@interfaces/plant.interface';
import Concept from './concept.class';

export default class PartitionClass {

  index: number[] = [];

  concept: Concept;

  _data: Partition[] = [];

  constructor(data?: Partition[], concept?: Concept) {

    if (data) this._data = data;
    if (concept) this.concept = concept;

  }

  set data(data: Partition[]) {

    this._data = data;
    this.createIndex();
    this.refresh();

  }

  get data() {

    return this._data;

  }

  single(id: number) {

    return this.data[this.index.indexOf(id)];

  }

  createIndex() {

    this.index = [];
    this.data.forEach((partition: Partition) => {

      this.index.push(partition.id);

    });

  }

  /**
     * returns true if partition is new
     *
     * @returns
     * @memberof PartitionClass
     */
  refresh() {

    let updatePartition = false;

    this.concept.plants.forEach((plant: Plant) => {

      if (!this.single(plant.id)) {

        this._data.push({
          id: plant.id,
          amount: 0,
          percent: 100 / this.concept.plants.length,
          base: plant.amount,
          size: 0,
        });

        this.index.push(plant.id);
        updatePartition = true;

      }

    });

    return updatePartition;

  }

  reset() {

    this._data = [];
    this.refresh();

  }

  remove(plantId: number) {

    if (!this.single(plantId)) return;

    const singlePercent = this.single(plantId).percent / this.concept.plants.length;
    const index = this.index.indexOf(plantId);

    this._data.splice(index, 1);
    this.index.splice(index, 1);

    this._data.forEach((partition: Partition) => {

      partition.percent += singlePercent;

    });

  }

  add(plantId: number) {

    let percent = 0;
    let all = 0;

    this._data.forEach((partition: Partition) => {

      all += partition.percent;

      const singlePercent = (partition.percent / 100) * 10;

      partition.percent -= singlePercent;

      percent += singlePercent;

    });

    this._data.push({
      percent,
      id: plantId,
      amount: 0,
      base: this.concept.plant(plantId).amount,
      size: 0,
    });

    this.index.push(plantId);

  }

  export(): Partition[] {

    return this._data;

  }

}

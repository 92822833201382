import ConfigScreen from '@screens/config.screen.vue';

import ChallengeScreen from '@screens/challenge.screen.vue';
import { ConceptGuardv2, Guard, ShareConceptGuardv2, SignupGuard } from '@libs/lila-auth';
import { RouteConfig } from 'vue-router/types/router';

const loadScreen = (screen: string) => {
  return () => import(/* webpackPrefetch: true */`./screens/${screen}`).then(m => m.default);
};

const routes: RouteConfig[] = [
  {
    path: '/login',
    name: 'login',
    component: loadScreen('login.screen.vue'),
    meta: {
      authAllowed: false,
    },
  },
  {
    path: '/login/callback',
    name: 'login-callback',
    component: loadScreen('login.screen.vue'),
  },
  {
    path: '',
    component: loadScreen('main.screen.vue'),
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: '/membership',
        name: 'membership',
        meta: {
          useSiteTitle: true,
        },
        beforeEnter: Guard,
        component: loadScreen('membership.screen.vue'),
      },
      {
        name: 'concept',
        meta: {
          authRequired: true,
          config: true,
          menuHidden: true
        },
        path: '/concept/:bedSize?',
        beforeEnter: Guard,
        component: ConfigScreen,
      },
      {
        name: 'config',
        meta: {
          authRequired: true,
          config: true,
          menuHidden: true
        },
        path: '/config/:step1?/:step2?/:step3?/:step4?/:step5?/:step6?',
        beforeEnter: Guard,
        component: ConfigScreen,
      },
      {
        name: 'content',
        path: '/i/:id',
        meta: {
          useSiteTitle: true,
        },
        beforeEnter: Guard,
        component: loadScreen('content.screen.vue'),
      },
      {
        name: 'member-content',
        path: '/m/:id',
        meta: {
          useSiteTitle: true,
        },
        beforeEnter: Guard,
        component: loadScreen('content.screen.vue'),
      },
      {
        path: '/logout',
        name: 'logout',
        component: loadScreen('login.screen.vue'),
      },
      {
        path: 'concepts/:site?',
        name: 'me-concepts',
        meta: {
          authRequired: true,
          contentUpdateMenuAnimation: false
        },
        beforeEnter: Guard,
        component: loadScreen('me-concepts.screen.vue'),
      },
      {
        path: 'me',
        name: 'me-home-link',
        meta: {
          authRequired: true,
        },
        beforeEnter: Guard,
        component: loadScreen('me-home.screen.vue'),
      },
      {
        path: '/kurs/:id?',
        name: 'kurs',
        meta: {
          authRequired: true,
          concept: true,
          useSiteTitle: true,
        },
        beforeEnter: Guard,
        component: loadScreen('learn.screen.vue'),
      },
      {
        path: 'me',
        name: 'me-verify',
        meta: {
          authRequired: true,
        },
        beforeEnter: Guard,
        component: loadScreen('me-home.screen.vue'),
      },
      {
        path: '/upgrade',
        name: 'upgrade',
        component: loadScreen('upgrade.screen.vue'),
      },
      {
        path: '/gartenclub-upgrade',
        name: 'gartenclub-upgrade',
        component: loadScreen('upgrade.screen.vue'),
      },
      {
        path: '',
        name: 'me-home',
        meta: {
          authRequired: true,
          useSiteTitle: true
        },
        beforeEnter: Guard,
        component: loadScreen('me-home.screen.vue'),
      },
    ]
  },
  {
    name: 'challenge-content',
    path: '/challenge/:challenge/c/:id/:signup?',
    beforeEnter: SignupGuard,
    component: ChallengeScreen,
  },
  {
    name: 'challenge',
    path: '/challenge/:challenge/:part/:step1?/:step2?/:step3?/:step4?/:step5?/:step6?',
    beforeEnter: Guard,
    component: ConfigScreen,
  },
  {
    name: 'editor',
    path: '/editor-child',
    component: loadScreen('editor-child.screen.vue'),
  },
  {
    name: 'editor-plants',
    path: '/editor-plants',
    component: loadScreen('editor-plants.screen.vue'),
  },
  {
    name: '',
    path: '/s/:id',
    component: loadScreen('shared.screen.vue'),
    beforeEnter: ShareConceptGuardv2,
    children: [
      {
        path: 'overview',
        name: 'share_concept_overview',
        meta: {
          share: true,
          concept: true,
          menuHidden: true
        },
        component: loadScreen('concept-intro.screen.vue'),
      },
      {
        path: 'plants',
        name: 'share_concept_plants',
        meta: {
          share: true,
          concept: true,
        },
        component: loadScreen('concept-plants.screen.vue'),
      },
      {
        path: 'colors',
        name: 'share_concept_colors',
        meta: {
          share: true,
          concept: true,
        },
        component: loadScreen('concept-colors.screen.vue'),
      },
      {
        path: 'partition',
        name: 'share_concept_partition',
        meta: {
          share: true,
          concept: true,
        },
        component: loadScreen('concept-partition.screen.vue'),
      },
      {
        path: 'heights',
        name: 'share_concept_heights',
        meta: {
          share: true,
          concept: true,
        },
        component: loadScreen('concept-heights.screen.vue'),
      },
      {
        path: '',
        name: 'share_concept_overview_default',
        meta: {
          share: true,
          concept: true,
        },
        component: loadScreen('concept-intro.screen.vue'),
      },
    ],
  },
  {
    path: '/c/:id',
    beforeEnter: ConceptGuardv2,
    meta: {
      authRequired: true,
      concept: true,
      menuHidden: true
    },
    component: loadScreen('concept.screen.vue'),
    children: [
      {
        path: 'overview',
        name: 'concept_overview',
        beforeEnter: Guard,
        meta: {
          authRequired: true,
          concept: true,
          menuHidden: true
        },
        component: loadScreen('concept-intro.screen.vue'),
      },
      {
        path: 'plants',
        name: 'concept_plants',
        beforeEnter: Guard,
        meta: {
          authRequired: true,
          concept: true,
          menuHidden: true
        },
        component: loadScreen('concept-plants.screen.vue'),
      },
      {
        path: 'hints',
        name: 'concept_hints',
        beforeEnter: Guard,
        meta: {
          authRequired: true,
          concept: true,
          menuHidden: true
        },
        component: loadScreen('concept-hints.screen.vue'),
      },
      {
        path: 'colors',
        name: 'concept_colors',
        beforeEnter: Guard,
        meta: {
          authRequired: true,
          concept: true,
          menuHidden: true
        },
        component: loadScreen('concept-colors.screen.vue'),
      },
      {
        path: 'edit/:site?',
        name: 'concept_edit',
        beforeEnter: Guard,
        meta: {
          authRequired: true,
          concept: true,
          menuHidden: true,
          contentUpdateMenuAnimation: false
        },
        component: loadScreen('concept-edit.screen.vue'),
      },
      {
        path: 'partition',
        name: 'concept_partition',
        beforeEnter: Guard,
        meta: {
          authRequired: true,
          concept: true,
          menuHidden: true
        },
        component: loadScreen('concept-partition.screen.vue'),
      },
      {
        path: 'heights',
        name: 'concept_heights',
        beforeEnter: Guard,
        meta: {
          authRequired: true,
          concept: true,
          menuHidden: true
        },
        component: loadScreen('concept-heights.screen.vue'),
      },
      {
        path: 'feedback',
        name: 'concept_feedback',
        beforeEnter: Guard,
        meta: {
          authRequired: true,
          concept: true,
          menuHidden: true
        },
        component: loadScreen('feedback/feedback.screen'),
      },
      {
        path: 'buy',
        name: 'concept_buy',
        beforeEnter: Guard,
        meta: {
          authRequired: true,
          concept: true,
          menuHidden: true
        },
        component: loadScreen('buy/buy.screen'),
      },
      {
        path: 'shopping',
        name: 'concept_shopping',
        beforeEnter: Guard,
        meta: {
          authRequired: true,
          concept: true,
          menuHidden: true
        },
        component: loadScreen('concept-shopping.screen.vue'),
      },
      {
        path: 'options',
        name: 'concept_options',
        beforeEnter: Guard,
        meta: {
          authRequired: true,
          concept: true,
          menuHidden: true
        },
        component: loadScreen('concept-options.screen.vue'),
      },
      {
        name: 'bonus',
        path: 'bonus',
        beforeEnter: Guard,
        meta: {
          authRequired: true,
          concept: true,
          menuHidden: true
        },
        component: loadScreen('content.screen.vue'),
      },
      {
        path: '*',
        name: 'concept_overview_default',
        beforeEnter: Guard,
        meta: {
          authRequired: true,
          concept: true,
          menuHidden: true
        },
        component: loadScreen('concept-intro.screen.vue'),
      },
    ],
  },
  {
    name: 'content-public',
    path: '/p/:id',
    meta: {
      noContentUpdate: true
    },
    component: loadScreen('content.screen.vue'),
  },
  {
    path: '/admin',
    component: loadScreen('admin.screen.vue'),
    beforeEnter: Guard,
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: '/user/:site?',
        name: 'admin-user-default',
        beforeEnter: Guard,
        meta: {
          authRequired: true,
        },
        component: loadScreen('admin-user.screen.vue'),
      },
      {
        path: '',
        name: 'admin-user-default',
        beforeEnter: Guard,
        meta: {
          authRequired: true,
        },
        component: loadScreen('admin-user.screen.vue'),
      },
    ],
  },
  // {
  //   path: '',
  //   component: loadScreen('me.screen.vue'),
  //   beforeEnter: Guard,
  //   meta: {
  //     authRequired: true,
  //   },
  //   children: [

  //   ],
  // },
];


export default routes;
/* eslint-disable max-len */
export default {
  required: 'erforderlich',
  submit: 'senden',
  bloom: 'Blüte',
  bloomcolor: 'Blütefarbe',
  bloomtimes: {
    1: 'Frühlingsblüher',
    2: 'Sommerblüher',
    3: 'Herbstblüher',
    4: 'Basispflanzen',
    5: 'Basispflanzen',
    6: 'Bodendecker',
  },
  color: 'Blütefarbe',
  color2: 'Blattfarbe',
  colors: {
    1: 'Rot',
    2: 'Orange',
    3: 'Gelb',
    4: 'Rosa',
    5: 'Pink',
    6: 'Violett',
    7: 'Blau',
    8: 'Weiß',
    9: 'Grün',
    10: 'Braun/Beige',
  },
  'app-gfm-leafcolor-1': 'hellgrün',
  'app-gfm-leafcolor-2': 'sattgrün',
  'app-gfm-leafcolor-3': 'dunkelgrün',
  'app-gfm-leafcolor-4': 'blaugrün / silber',
  'app-gfm-leafcolor-5': 'mehrfarbig',
  'app-gfm-leafcolor-6': 'braun/beige',
  'app-gfm-leafcolor-7': 'rötlich',
  month: {
    0: 'Januar',
    1: 'Februar',
    2: 'März',
    3: 'April',
    4: 'Mai',
    5: 'Juni',
    6: 'Juli',
    7: 'August',
    8: 'September',
    9: 'Oktober',
    10: 'November',
    11: 'Dezember',
  },
  size: 'Größe',
  poisonous: 'Giftig',
  poison: 'Giftigkeit',
  location: 'Standort',
  lifespan: 'Lebenszeit',
  planttime: 'Pflanzzeit',
  water: 'Wasser',
  'add plant': 'Pflanze hinzufügen',
  bedsize: 'Beetfläche in m²',
  sizeqm: 'Fläche in m²',
  next: 'weiter',
  back: 'zurück',
  style: 'Stil',
  sun: 'Sonne',
  soil: 'Boden',
  time: 'Zeit',
  bees: 'Bienen',
  plantdistance: 'Pflanzabstand',
  diameter: 'Durchmesser',
  winter: 'Winterhart',
  category: 'Kategorie',
  plants: 'Pflanzen',
  cmYear: 'Wuchsgeschwindigkeit',
  amount: 'Anzahl pro QM',
  soilType: 'Bodenart',
  areas: 'Lebensbereich Stauden',
  colorname: 'Farbe',
  heyday: 'Blütezeit',
  root: 'Wurzelsystem',
  growth: 'Wuchs',
  conviviality: 'Geselligkeit',
  purpose: 'Aufgabe im Garten',
  purposeInBed: 'Aufgabe im Beet',
  habit: 'Wuchsform',
  shape: 'Blüteform',
  animalCompatibility: 'Insektenart',
  animalResistance: 'Resistenz',
  fertilize: 'Düngebedarf',
  insects: 'Insektenfreundlich',
  fruit: 'Frucht',
  properties: 'Blatteigenschaft',

  BONUS: 'Bonus',

  CONFIG_WET: 'feucht',
  CONFIG_NORMAL: 'normal',
  CONFIG_DRY: 'trocken',

  CONFIG_TIME: 'Ich habe Zeit',
  CONFIG_NOTIME: 'Ich habe kaum Zeit',

  CONFIG_SUNNY: 'sonnig',
  CONFIG_PARTIAL: 'halbschattig',
  CONFIG_SHADY: 'schattig',

  CONFIG_JUNGLE: 'Junglelicious',
  CONFIG_ANSWER_JUNGLE: 'Junglelicious',
  CONFIG_SIMPLE: 'Cosmopolitan',
  CONFIG_ANSWER_SIMPLE: 'Cosmopolitan',
  CONFIG_NATURE: 'Back to Nature',
  CONFIG_ANSWER_NATURE: 'Back to Nature',
  CONFIG_DREAM: 'Blütentraum',
  CONFIG_ANSWER_DREAM: 'Blütentraum',
  CONFIG_INSECTS: 'Save the Bees',
  CONFIG_ANSWER_INSECTS: 'Save the Bees',

  CONFIG_NOPOISON: 'Nein',
  CONFIG_POISON: 'Ja',

  SEARCH_NOPOISON: 'nur ungiftige Pflanzen anzeigen',
  SEARCH_POISON: 'giftige Pflanzen sind ok',

  CONCEPT_OVERVIEW: 'Vorschau',
  CONCEPT_OVERVIEW_DEFAULT: 'Vorschau',
  CONCEPT_EDIT: 'Bearbeiten',
  CONCEPT_HINTS: 'Tipps',
  CONCEPT_COLORS: 'Blütekalender',
  concept_colors: 'Blütekalender',
  CONCEPT_NEW: 'Neues Konzept',
  CONCEPT_PLANTS: 'Pflanzen',
  CONCEPT_PARTITION: 'Aufteilung',
  CONCEPT_HINTS_CHOOSEN: 'Weil du dich für bei der Frage ',
  CONCEPT_HINTS_EXT: 'für',
  CONCEPT_HINTS_AFTER: 'entschieden hast.',
  CONCEPT_FEEDBACK: 'Feedback',
  CONCEPT_SHOPPING: 'Shoppingliste',
  CONCEPT_BUY: 'Shoppingliste',
  CONCEPT_SHOPPINGPDF: 'PDF download',
  CONCEPT_HEIGHTS: 'Höhen',
  concept_heights: 'Höhen',
  CONCEPT_OPTIONS: 'Optionen',
  titles_color: 'Farben',
  titles_hints: 'Tipps',
  titles_overview: 'Konzept',
  titles_edit: 'Übersicht',
  titles_plants: 'Pflanzen',
  titles_partition: 'Aufteilung',
  TEXT_JUNGLE: 'Exotische Dschungelgärten sind auch in unseren Klimaten umsetzbar, sie überzeugen vor allem mit Blattstrukturen, außergewöhnlichen Pflanzen und vielen Grüntönen. Blütenfarben spielen eine eher untergeordnete Rolle, setze sie also gezielt als Highlights in deinem Beet ein und konzentriere dich eher auf Basispflanzen, mit exotischen Blattstrukturen.',
  TEXT_SIMPLE: 'Beim minimalistischen Design ist ganz klar weniger mehr. Begrenze deine Pflanzenauswahl auf wenige verschiedene Pflanzen und setze diese Pflanzen in deinem Beet eher flächendeckend ein. Die Anordnung im Beet spielt hier die wichtigste Rolle. Klare Strukturen und klare Linien geben deinem Beet das minimalistische Design.',
  TEXT_NATURE: 'Naturalistische Gärten erinnern an Prärien, setze viele Gräser ein und konzentriere dich auf filigrane Pflanzen mit weichen Strukturen und vielen kleinen Blüten. Dieser Stil überzeugt mit geplanter Unordnung oder auch einer wilden, naturbelassenen Anordnung.',
  TEXT_DREAM: 'Der Blütenreiche Stil erinnert an einen romantischen Cottage Garten. Wild sollte es sein, mit vielen geschwungenen Beeten und Wegen. Konzentriere dich bei der Pflanzenwahl auf viele farbenfrohe Blüten und verspielte Formen.',
  TEXT_INSECTS: 'Du hast dich für eine insektenfreundliche und ökologische Bepflanzung entschieden, was wir toll finden! In dieser Kategorie findest du auch Pflanzen, die nicht auf den ersten Blick wie der klassische Bienenmagnet wirken, da wir alle Insekten berücksichtigen möchten. Hier findest du vor allem auch heimische Wildstauden, die unsere Fauna fördern und die Nahrungsquelle der Insekten bilden.',
  TEXT_SUNNY: 'Sonnige Pflanzen sind die Superhelden unter den Pflanzen, da sie sehr widerstandsfähig sind und die heiße Mittagssonne gut aushalten. Das macht sie oft zu pflegeleichten Begleitern. Bekommen die Sonnenliebhaber zu wenig Sonne, blühen Sie weniger, wachsen langsamer und sind angreifbarer für Krankheiten.',
  TEXT_PARTIAL: 'Halbschattige Pflanzen sind durch ihre feinen Blattstrukturen empfindlicher, daher halten sie die volle Mittagssonne kaum aus. Bei leichter Vormittags- oder Abendsonne entwickeln sich halbschattige Pflanzen am besten.',
  TEXT_SHADY: 'Schattige Pflanzen sind die Sensibelchen unter den Pflanzen, denn sie vertragen kaum direktes Sonnenlicht. Man findet sie oft in schattigen Wäldern zu Hause. In der Sonne bekommen Sie schnell einen Sonnenbrand. Bei schattigen Pflanzen gibt es weniger Auswahl an blühenden Pflanzen, dafür kann man das Beet hervorragend mit verschiedenen Blattstrukturen und grünen Farbnuancen gestalten.',
  TEXT_TIME: 'Du scheinst gerne Zeit in deinem Garten zu verbringen, wie schön! Wusstest du das Gartenarbeit zu den beliebtesten Freizeitaktivitäten zählt? Obendrein ist Gärtnern auch gesund: Es baut z.B. Stress ab.',
  TEXT_NOTIME: 'Du scheinst nicht viel Zeit für deinen Garten zu haben, möchtest aber trotzdem eine Erholungsoase haben? Kein Problem, es geht zwar nicht ganz ohne Arbeit, aber wir haben bei der Pflanzenwahl darauf geachtet, dass die Pflege deiner Pflanzen überschaubar ist.',
  TEXT_WET: 'Feuchte Böden entstehen meist durch einen hohen Lehmanteil, der das Wasser bindet. Der Nachteil dieser Böden ist, dass sie bei feuchter Witterung sehr nass und schwer werden und bei Trockenperioden können sie sehr hart werden. Wir empfehlen dir hier Pflanzen, die mit einem feuchten Boden in der Regel klarkommen.',
  TEXT_NORMAL: 'Dein Gartenboden ist perfekt für diese üppigen Stauden, denn er trocknet weder schnell aus, noch wird er zu nass. Durch regelmäßige Kompostzugabe und Auflockern des Bodens kannst du die gute Bodenqualität aufrechterhalten.',
  TEXT_DRY: 'Du hast vermutlich einen hohen Sand- oder Kiesanteil in deinem Gartenboden. Manchmal sind auch umliegende Pflanzen, die einen hohen Wurzeldruck ausüben an einem trockenen Boden Schuld. Wir empfehlen dir Pflanzen, die mit diesem Boden gut zurechtkommen sollten.',
  TEXT_POISON: 'Wir haben bei der Pflanzenwahl darauf geachtet, dass keine giftigen Pflanzen dabei sind, die gefährlich für deine Kinder oder Tiere sein können. Beachte, dass deine Pflanzenwahl dadurch sehr eingeschränkt ist, da viele Pflanzen schon schwach giftig sind, selbst ein Efeu gilt als giftig.',
  MONTH_JANUARY: 'Januar',
  MONTH_FEBRUARY: 'Februar',
  MONTH_MARCH: 'März',
  MONTH_APRIL: 'April',
  MONTH_MAY: 'Mai',
  MONTH_JUNE: 'Juni',
  MONTH_JULY: 'Juli',
  MONTH_AUGUST: 'August',
  MONTH_SEPTEMBER: 'September',
  MONTH_OCTOBER: 'Oktober',
  MONTH_NOVEMBER: 'November',
  MONTH_DECEMBER: 'Dezember',
  FEEDBACK_NAME: 'Dein Name',
  FEEDBACK_TEXT_LABEL: 'Deine Kritik',
  FEEDBACK_FEATURES_TITLE: 'Neue Features',
  FEEDBACK_FEATURES_TEXT: 'Welche der nachfolgenden Features würdest du nutzen?',
  FEEDBACK_SEND: 'FEEDBACK SENDEN',
  FEEDBACK_TEXT_PLACEHOLDER: 'Deine Nachricht an uns',

  CONCEPT_NAME_JUNGLELICIOUS: 'Junglelicious',
  CONCEPT_NAME_JUNGLELICIOUS_DESC: 'exotisch / verschiedene Blattstrukturen / außergewöhnliche Pflanzen',

  CONCEPT_NAME_COSMOPOLITAN: 'Cosmopolitan',
  CONCEPT_NAME_COSMOPOLITAN_DESC: 'klare Linien / ausgefallene Blattformen / minimalistisch',

  CONCEPT_NAME_BACKTONATURE: 'Back to nature',
  CONCEPT_NAME_BACKTONATURE_DESC: 'natürliches Wachstum / filigrane Blüten / viele Gräser',

  CONCEPT_NAME_FLOWERPOWER: 'Flower Power',
  CONCEPT_NAME_FLOWERPOWER_DESC: 'romantisch / wilde Strukturen / blütenreich',

  CONCEPT_NAME_SAVETHEBEES: 'Save the Bees',
  CONCEPT_NAME_SAVETHEBEES_DESC: 'insektenfreundlich / heimische Wildstauden / ökologisch',

  HINT_TITLE_STYLE: 'Dein Gartenstil',
  HINT_TITLE_SUN: 'Sonne',
  HINT_TITLE_SOIL: 'Deine Bodenart',
  HINT_TITLE_TIME: 'Deine Zeit',
  HINT_TITLE_POISON: 'Giftigkeit',

  ME_HOME: 'Meine Konzepte',
  MY_CONCEPTS: 'Meine Konzepte',
  NO_CONCEPT_NAME: 'Konzept ohne Name',
  NO_CONCEPT_DESCRIPTION: 'Keine Beschreiung',

  PLANT_NOT_VISIBLE: 'Die Pflanze zieht sich temporär in den Boden zurück',
  BLOOMPHASE: 'Blütephase',
  COLORCHANGE_BROWN: 'Die Pflanze verfärbt sich braun',

  growdistance: 'Wuchsbreite',

  HINT_ANSWER_TITLE_STYLE: 'Ausgewählter Stil:',

  HINT_ANSWER_TITLE_SUN: 'Ausgewählter Standort:',
  CONFIG_ANSWER_SUNNY: 'Sonnig',
  CONFIG_ANSWER_PARTIAL: 'Halbschattig',
  CONFIG_ANSWER_SHADY: 'Schattig',

  HINT_ANSWER_TITLE_SOIL: 'Ausgewählte Bodenfeuchte:',
  CONFIG_ANSWER_WET: 'Feucht',
  CONFIG_ANSWER_NORMAL: 'Normal',
  CONFIG_ANSWER_DRY: 'Trocken',

  HINT_ANSWER_TITLE_POISON: 'Ausgewählte Giftigkeit:',
  CONFIG_ANSWER_POISON: 'Giftige Pflanzen sind ok',
  CONFIG_ANSWER_NOPOISON: 'Keine giftigen Pflanzen erlaubt',

  HINT_ANSWER_TITLE_TIME: 'Ausgewählte Zeit:',
  CONFIG_ANSWER_NOTIME: 'Kaum Zeit',
  CONFIG_ANSWER_TIME: 'Ein paar Stunden die Woche',

  delete: 'löschen',
  confirmAction: 'bitte bestätige die Aktion',
  confirmed: 'bestätigt',

  LOGIN: 'Login',
  SIGNUP: 'Account erstellen',

  EMAIL_VERIFIED: 'Ich habe meine E-Mail bestätigt',

  UPGRADE: 'Mitglied im Gartenclub werden',

  CLUBMEMBER: 'Mitglied im Gartenclub.',

  NO_CLUBMEMBER: 'Kostenloser Account, kein Clubmitglied.',

  SHARED_CONCEPT_NAME: 'Dein Konzept',

  CTA_SHARED: 'Werde Mitglied',

  cancel: 'abbrechen',

  save: 'speichern',

  description: 'Beschreibung',

  VERIFY_EMAIL: 'Code senden',

  VERIFY_WRONG_CODE: 'Der eingegebe Code ist falsch',

  groupbloom: 'Pflanzen nach Blütezeit gruppieren',

  expertmode: 'Expertenmodus',

  NEXT_SITE: 'weitere',

  PREV_SITE: 'vorherige',

  ACTIVATE_KEY: 'Aktivierungsschlüssel',

  ACTIVATE_MEMBERSHIP: 'Mitgliedschaft aktivieren',

  ACTIVATE_CODE: 'Code aktivieren',

  ORDER_NOT_FOUND: 'Es wurde keine Bestellung mit diesem Schlüssel gefunden.',

  ORDER_ALREADY_USED: 'Dieser Schlüssel wurde bereits benutzt.',

  ORDER_WRONG_PRODUCT: 'Mit diesem Schlüssel kann keine Mitgliedschaft aktiviert werden.',

  ORDER_NOT_PAID: 'Dieser Schlüssel ist noch nicht aktiv, bitte versuche es später erneut.',

  NO_MEMBERSHIP_TO_EXTEND: 'Dieser Schlüssel ist nur fuer eine Verlaengerung der Mitgliedschaft valide. Du hast keine aktive Mitgliedschaft.',

  WORKING_ACTIVATION: 'Deine Mitgliedschaft wird aktiviert, gleich gehts weiter.',

  ACTIVATION_COMPLETE: 'Wunderbar, deine Mitgliedschaft wurde aktiviert.',

  WINTER_TRUE: 'ja',
  INSECTS_TRUE: 'ja',

  'gfm-app-lifespan-1': 'einjährig',
  'gfm-app-lifespan-2': 'zweijährig',
  'gfm-app-lifespan-3': 'mehrjährig',

  'app-gfm-winter-green-always': 'Immergrüne Pflanze',
  'app-gfm-winter-green-summer': 'Sommergrüne Pflanze',
  'app-gfm-winter-green-winter': 'Wintergrüne Pflanze',
  'app-gfm-winter-green-half': 'Halbimmergrüne Pflanze',

  'app-gfm-conviviality-1': 'I einzeln oder in kleinen Tuffs',
  'app-gfm-conviviality-2': 'II in kleinen Trupps von etwa 3-10 Pflanzen',
  'app-gfm-conviviality-3': 'III in größeren Gruppen von 10-20 Pflanzen',
  'app-gfm-conviviality-4': 'IV in größeren Kolonien, ausgesprochen flächig',
  'app-gfm-conviviality-5': 'V vorwiegend großflächig',
  'app-gfm-areas-1': 'G  - Gehölz',
  'app-gfm-areas-2': 'GR - Gehölz-Rand',
  'app-gfm-areas-3': 'Fr - Freifläche mit Wildstaudencharakter',
  'app-gfm-areas-4': 'B  - Beet',
  'app-gfm-areas-5': 'SH - Freifläche mit Steppen-Heide-Charakter',
  'app-gfm-areas-6': 'H  - Freifläche mit Heide-Charakter',
  'app-gfm-areas-7': 'St - Steinanlage',
  'app-gfm-areas-8': 'FS - Fels-Steppe',
  'app-gfm-areas-9': 'M  - Matten',
  'app-gfm-areas-10': 'SF - Stein-Fugen',
  'app-gfm-areas-11': 'MK - Mauer-Kronen',
  'app-gfm-areas-12': 'A  - Alpinum',
  'app-gfm-areas-13': 'WR - Wasser-Rand',
  'app-gfm-areas-14': 'W  - Wasserpflanzen',
  'app-gfm-areas-15': 'KÜBEL - nicht winterharte Stauden',
  'app-gfm-purposeInBed-1': 'Leitpflanze',
  'app-gfm-purposeInBed-2': 'Begleitpflanze',
  'app-gfm-purposeInBed-3': 'Füllpflanze',
  'app-gfm-purposeInBed-4': 'Streupflanze',
  'app-gfm-purpose-1': 'Erosionsschutz /Hang',
  'app-gfm-purpose-2': 'Sichtschutz',
  'app-gfm-purpose-3': 'Sicherheit',
  'app-gfm-purpose-4': 'Lärmschutz',
  'app-gfm-purpose-5': 'Unkrautunterdrücker',
  'app-gfm-purpose-6': 'Duftpflanzen',
  'app-gfm-purpose-7': 'Windschutz',
  'app-gfm-purpose-8': 'Schattenspender',
  'app-gfm-purpose-9': 'Raumteiler',
  'app-gfm-habit-1': 'rund & kugelig',
  'app-gfm-habit-2': 'gewölbt & oval',
  'app-gfm-habit-3': 'konisch & pyraamidal',
  'app-gfm-habit-4': 'säulenförmig',
  'app-gfm-habit-5': 'stachelig',
  'app-gfm-habit-6': 'trichterförmig',
  'app-gfm-habit-7': 'quadratisch',
  'app-gfm-habit-8': 'frei wachsend',
  'app-gfm-shape-1': 'Ähren & Trauben',
  'app-gfm-shape-2': 'Köpfchen & Quirle',
  'app-gfm-shape-3': 'Rispen',
  'app-gfm-shape-4': 'Dolden',
  'app-gfm-shape-5': 'Magaritenblumen',
  'app-gfm-shape-6': 'Netze & Gitter',
  'gfm-app-white': 'weiss',
  'gfm-app-no-color': 'Keine Farbe',
  'gfm-app-multi-color': 'mehrfarbig',
  'app-gfm-growth-1': 'horstbildend',
  'app-gfm-growth-2': 'polsterbildend',
  'app-gfm-growth-3': 'rhizombildend',
  'app-gfm-growth-4': 'kompakt',
  'app-gfm-root-1': 'tief',
  'app-gfm-root-2': 'flach',
  'app-gfm-root-3': 'herz',
  'app-gfm-bloomCategory-1': 'Frühlingsblüher',
  'app-gfm-bloomCategory-2': 'Sommerblüher',
  'app-gfm-bloomCategory-3': 'Herbstblüher',
  'app-gfm-bloomCategory-4': 'Winterblüher',
  'app-gfm-bloomCategory-5': 'Basispflanzen',
  'app-gfm-bloomCategory-6': 'Bodendecker',
  'gfm-app-location-1': 'Halbschatten',
  'gfm-app-location-2': 'sonnig',
  'gfm-app-location-3': 'schattig',
  'gfm-app-location-4': 'absonnig',
  'gfm-app-soil-1': '3 - feucht',
  'gfm-app-soil-2': '2 - normal',
  'gfm-app-soil-3': '1 - trocken',
  'gfm-app-soil-4': '4 - nasser Boden (Sumpf)',
  'gfm-app-soil-5': '5 - flaches Wasser',
  'gfm-app-soil-6': '6 - Schwimmblattpflanzen',
  'gfm-app-soil-7': '7 - untergetauchte Pflanzen',
  'gfm-app-soil-8': '8 - Schwimmpflanzen',
  'gfm-app-soil-plantcard-1': 'feucht',
  'gfm-app-soil-plantcard-2': 'normal',
  'gfm-app-soil-plantcard-3': 'trocken',
  'gfm-app-soil-plantcard-4': 'nasser Boden (Sumpf)',
  'gfm-app-soil-plantcard-5': 'flaches Wasser',
  'gfm-app-soil-plantcard-6': 'Schwimmblattpflanzen',
  'gfm-app-soil-plantcard-7': 'untergetauchte Pflanzen',
  'gfm-app-soil-plantcard-8': 'Schwimmpflanzen',
  'app-gfm-category-1': 'Laubgehölze',
  'app-gfm-category-2': 'Nadelgehölze',
  'app-gfm-category-3': 'Heckenpflanzen',
  'app-gfm-category-4': 'Obst',
  'app-gfm-category-5': 'Stauden',
  'app-gfm-category-6': 'Rosen',
  'app-gfm-category-7': 'Bodendecker',
  'app-gfm-category-8': 'Kletterpflanzen',
  'app-gfm-category-9': 'Kräuter',
  'app-gfm-category-10': 'Gräser',
  'app-gfm-category-11': 'Farne',
  'app-gfm-category-12': 'Blumenzwiebeln/ Knollen',
  'app-gfm-category-13': 'Saatgut',
  'app-gfm-category-14': 'Sträucher',
  'app-gfm-category-15': 'Bambus',
  'app-gfm-category-16': 'Clematis',
  'app-gfm-category-17': 'Rhododendren',
  'app-gfm-category-18': 'Teichpflanzen',
  'app-gfm-category-19': 'Einjährige',
  'app-gfm-category-20': 'Blütenstauden',
  'app-gfm-category-21': 'Blattschmuckstauden',
  'app-gfm-category-22': 'Halbstrauch',
  'app-gfm-style-1': 'Flower Power',
  'app-gfm-style-2': 'Jungle',
  'app-gfm-style-3': 'Cosmopolitan',
  'app-gfm-style-4': 'Back to nature',
  'app-gfm-style-5': 'save the bees',
  'app-gfm-style-6': 'mediterran',
  'gfm-app-animalCompatibility-1': 'Schmetterlinge',
  'gfm-app-animalCompatibility-2': 'bienenfreundlich',
  'gfm-app-animalCompatibility-3': 'Vögel',
  'gfm-app-animalResistance-1': 'schneckenresitent',
  'gfm-app-animalResistance-2': 'kaninchenresitent',
  'gfm-app-animalResistance-3': 'wühlmäuseresistent',
  'gfm-app-soilType-1': 'Sandboden',
  'gfm-app-soilType-2': 'Lehmboden',
  'gfm-app-soilType-3': 'Tonboden',
  'gfm-app-soilType-4': 'leichter Boden',
  'gfm-app-soilType-5': 'schwerer Boden',
  'gfm-app-soilType-6': 'frischer/normaler Boden',
  'gfm-app-soilType-7': 'saurer Boden',
  'gfm-app-soilType-8': 'stickstoffarmer Boden',
  'gfm-app-soilType-9': 'stickstoffreicher Boden',
  'gfm-app-winterZone-1': 'Z1 unter -45,5',
  'gfm-app-winterZone-2': 'Z2 -45,5 bis -40,1',
  'gfm-app-winterZone-3': 'Z3 -40,1 bis -34,5',
  'gfm-app-winterZone-4': 'Z4 -34,5 bis -28,9',
  'gfm-app-winterZone-5': 'Z5 -28,8 bis -23,4',
  'gfm-app-winterZone-6': 'Z6 -23,4 bis -17,8 v. a. wintergrüne Pflanzen bei Kahlfrost schützen',
  'gfm-app-winterZone-7': 'Z7 -17,8 bis -12,3 v. a. wintergrüne Pflanzen bei Kahlfrost schützen',
  'gfm-app-winterZone-8': 'Z8 -12,3 bis -6,7 Winterschutz notwendig, z. B. aus Laub und/oder Reisig; in rauen Lagen möglichst drinnen hell und kühl überwintern',
  'gfm-app-winterZone-9': 'Z9 -6,7 bis -1,2 intensiver Winterschutz (z.B. mit Vlies); besser drinnen hell und kühl überwintern',
  'gfm-app-winterZone-10': 'Z10 -1,2 bis +4,4 frostfreie Überwinterung notwendig, 5-15 °C (z.B. Wintergarten, Treppenhaus)',
  'gfm-app-winterZone-11': 'Z11 über +4,4',

  'app-gfm-winterZone': 'Winterhärtezone',

  'app-gfm-time': 'Pflegeaufwand / Zeitbedarf',
  'gfm-app-time-1': 'Pflegeaufwand Stufe 1',
  'gfm-app-time-2': 'Pflegeaufwand Stufe 2',
  'gfm-app-time-3': 'Pflegeaufwand Stufe 3',
  'gfm-app-time-4': 'Pflegeaufwand Stufe 4',

  'me-concepts': 'Meine Konzepte',
  concept_edit: 'Bearbeiten',
  concept_plants: 'Pflanzen',
  concept_shopping: 'Shoppingliste',
  concept_hints: 'Tipps',
  concept_options: 'Optionen',
  concept_overview: 'Vorschau',
  concept_partition: 'Aufteilung',
  'me-home-link': 'Home',

  'membership-premium-name': 'Premium Mitglied',
  'membership-no-premium-name': 'Besucher',

  'membership-details-unlimited': 'Unbegrenzte Mitgliedschaft',
  'membership-details-start': 'Mitglied seit',
  'membership-details-end': 'Mitgliedschaft endet am',
  'membership-details-ended': 'Mitgliedschaft inaktiv seit',
  'membership-details-availableConcepts': 'Verfügbare Konzepte',
  'membership-details-availableConceptsText': '%s von %s angelegt.',
  'membership-details-title': 'Gartenclub',


};


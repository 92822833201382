import translate from '@mixins/translation';
import store from '@base/store/main.store';
import Textblock from '@interfaces/textblock.interface';
import ConceptExport from '@interfaces/conceptExport.interface';
import Groups from '@interfaces/groups.interface';
import Plant from '@interfaces/plant.interface';
import PartitionClass from './partition.class';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default class Concept {

  index: number[] = [];

  plants: Plant[] = [];

  groups: Groups = {
    1: [],
    2: [],
    3: [],
    5: [],
    6: [],
  };

  Partition: PartitionClass;

  style = '';

  sun = '';

  soil = '';

  poison = '';

  bedSize = 0;

  time = '';

  share = false;

  shareId = '';

  options = [
    'style',
    'sun',
    'soil',
    'poison',
    'bedsize',
    'time',
  ];

  name = '';

  description = '';

  _id = '';

  shopifyId: number;

  password = '';

  hints: Textblock[];

  createdAt: Date;

  updatedAt: Date;

  feedback: string;

  user: string;

  constructor(object?: Record<string, any>) {

    this.Partition = new PartitionClass(null, this);

    if (object) {

      Object.assign(this, object);
      this.createIndex();

      if (object.partition !== undefined && this.plants.length > 0) {

        this.Partition.data = object.partition;

      }

    }

  }

  plant(id: number) {

    return this.plants[this.index.indexOf(id)];

  }

  createIndex() {

    this.index = [];
    this.plants.forEach((plant: Plant) => {

      this.index.push(plant.id);

    });

  }

  refreshGroups() {

    const groups: Groups = {
      1: [],
      2: [],
      3: [],
      4: [],
      5: [],
      6: [],
    };

    this.plants.forEach((plant: Plant) => {

      let bloomId: number;

      if (!plant.bloomCategory) return;

      for (let a = 0; a < plant.bloomCategory.length; a += 1) {

        const bloom = plant.bloomCategory[a];

        if (!groups[bloom]) return;

        if (groups[bloom].length === 0) {

          bloomId = bloom;
          break;

        }

      }

      if (!bloomId) bloomId = plant.bloomCategory.slice().shift();

      groups[bloomId].push(plant);

    });

    this.groups = groups;

  }

  /**
     *
     *
     * @returns
     * @memberof Concept
     */
  getHints() {

    const hintModules: any[] = [];

    this.options.forEach((option: string) => {

      console.log(option);

      if (!this[option]) return;

      const textblock: any = {
        type: 'text-module',
      };
      const textkey = `TEXT_${this[option].toUpperCase()}`;

      console.log(textkey);
      if (!translate.isset(textkey)) return;

      textblock.subline = `
            ${translate.translate(`HINT_ANSWER_TITLE_${option.toUpperCase()}`)}
            ${translate.translate(`CONFIG_ANSWER_${this[option].toUpperCase()}`)}
            `;
      textblock.text = [translate.translate(textkey)];

      hintModules.push(textblock);

    });

    this.hints = hintModules;

    return hintModules;

  }

  /**
     * add to concept
     * refreshes the groups and adds the plant to partition
     *
     * @param {Plant} plant
     * @memberof Concept
     */
  add(plant: Plant) {

    this.plants.push(plant);
    this.index.push(plant.id);

    this.refreshGroups();

    this.Partition.add(plant.id);

  }

  /**
     * remove from concept
     * refreshes the groups and removes the plant from partition
     *
     * @param {Plant} plant
     * @memberof Concept
     */
  remove(plant: Plant) {

    const index = this.index.indexOf(plant.id);

    this.Partition.remove(plant.id);

    this.plants.splice(index, 1);
    this.index.splice(index, 1);

    this.refreshGroups();

  }

  get editable(): boolean {

    return this.user === store.state.user?._id;

  }

  // eslint-disable-next-line class-methods-use-this
  set editable(editable: boolean) {

  }

  /**
     *
     *
     * @returns {ConceptExport}
     * @memberof Concept
     */
  export(): ConceptExport {

    return {
      index: this.index,
      plants: this.plants,
      groups: this.groups,
      style: this.style,
      sun: this.sun,
      soil: this.soil,
      poison: this.poison,
      bedSize: this.bedSize,
      time: this.time,
      name: this.name,
      description: this.description,
      password: this.password,
      partition: this.Partition.export(),
      _id: this._id,
      shopifyId: this.shopifyId,
      feedback: this.feedback,
      editable: this.editable,
      user: this.user,
      share: this.share,
      shareId: this.shareId,
    };

  }

}

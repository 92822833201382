import vue from 'vue';
import Vuex from 'vuex';
import { Call } from '@libs/lila-call';
import Plant from '@interfaces/plant.interface';
import Concept from '@base/classes/concept.class';

import Config from '@store/config.store';
import Data from '@store/data.store';
import auth from '@libs/lila-auth';
import Settings from '@store/settings.store';
import Shared from '@store/shared.store';
import Navigation from '@store/navigation.store';
import Challenge from '@store/challenge.store';
import { MePermission, Me } from '@interfaces/Me.interface';
import ConceptExport from '@interfaces/conceptExport.interface';
import CallResponse from '@interfaces/lila-call.interface';
import Partition from '@interfaces/partition.interface';
import Feedback from '@interfaces/feedback.interface';
import { replace } from '@mixins/replacePlaceholder';
import MainStoreState from '@interfaces/MainStoreState.interface';

vue.use(Vuex);

const state: MainStoreState = {
  data: {
    options: {
      hideMenu: false,
      hideLogo: false,
    },
  },
  conceptData: {},
  title: '',
  media: '',
  description: '',
  partials: {},
  settings: {},
  translation: {},
  overlay: {
    content: {},
    active: false,
  },
  concept: {},
  partitionMonth: 5,
  shoppinglist: {},
  content: [],
  plantsList: {},
  editor: {
    state: '',
    active: false,
    type: '',
    content: [],
    modules: [],
    available: {},
  },
  fullscreen: false,
  configHelp: {},
  realHeight: null,
  lock: null,
  authToken: null,
  user: {
    prename: '',
    lastname: '',
    _id: null,
    permissions: [],
    email: '',
    membership: false,
    verified: false,
  },
  membership: {},
  availableModules: [],
  loading: null,
  inited: false,
  menuIsOpen: false,
  menuIsHidden: false,
  menuAnimation: true,
};

export default new Vuex.Store({
  state,
  modules: {
    Config,
    Data,
    Settings,
    Shared,
    Challenge,
    Navigation,
  },
  getters: {

    endpoints(mainState) {

      return mainState.settings.endpoints || [];

    },

  },
  mutations: {

    init(mainState) {

      mainState.inited = true;

    },

    setData(mainState, data) {

      mainState.data = data;

    },

    setConceptData(mainState, data) {

      mainState.conceptData = data;

    },

    set404(mainState) {

      mainState.data = { status: 404 };

    },

    setMedia(mainState, data) {

      mainState.media = data;

    },

    setPartials(mainState, data) {

      mainState.partials = data;

    },

    setSettings(mainState, data) {

      mainState.settings = data;

    },

    setTranslation(mainState, data) {

      mainState.translation = data;

    },

    setOverlay(mainState, data) {

      mainState.overlay.content = data;
      mainState.overlay.active = true;

    },

    clearOverlay(mainState) {

      mainState.overlay.content = {};

    },

    setConcept(mainState, concept: ConceptExport) {

      concept.password = 'true';
      mainState.concept = concept;

    },

    addPlantToConcept(mainState, concept: ConceptExport) {

      concept.password = 'true';
      mainState.concept = concept;

    },

    removePlantFromConcept(mainState, concept: ConceptExport) {

      concept.password = 'true';
      mainState.concept = concept;

    },

    setConceptMenu(mainState, rendered: boolean) {

      mainState.partials.conceptmenu.rendered = rendered;

    },

    toggleConceptMenu(mainState, open: boolean) {

      mainState.partials.conceptmenu.open = open;

    },

    setConceptPartition(mainState, partition: Partition[]) {

      mainState.concept.partition = partition;

    },

    setPartitionMonth(mainState, month: number) {

      mainState.partitionMonth = month;

    },

    setFeedback(mainState, feedbackId: string) {

      mainState.concept.feedback = feedbackId;

    },

    setContent(mainState, content: any) {

      mainState.content = content;

    },

    setEditorContent(mainState, content: any) {

      mainState.editor.content = content;

    },

    setEditorActive(mainState, active: string | number) {

      mainState.editor.active = active;

    },

    setEditorType(mainState, type: string) {

      mainState.editor.type = type;

    },

    setEditorAvailable(mainState, data: { available: boolean, type: string, value: string }) {

      mainState.editor.available[data.type] = data;

    },

    setEditorModules(mainState, data: string[]) {

      mainState.editor.modules = data;

    },

    setEditormainState(mainState, editorState: string) {

      mainState.editor.state = editorState;

    },

    setShoppinglist(mainState, shoppinglist: any) {

      mainState.shoppinglist = shoppinglist;

    },

    setFullscreen(mainState, fullscreen: boolean) {

      mainState.fullscreen = fullscreen;

    },

    setConfigHelp(mainState, data: any) {

      mainState.configHelp = data;

    },

    setHeight(mainState, height: number) {

      mainState.realHeight = height;

    },

    lock(mainState, routeName) {

      mainState.lock = routeName;

    },

    unlock(mainState) {

      mainState.lock = null;

    },

    loading(mainState, status) {

      mainState.loading = status;

    },

    setAuthToken(mainState, token: string) {

      mainState.authToken = token;

    },

    setUser(mainState, user) {

      mainState.user.prename = user.prename;
      mainState.user.lastname = user.lastname;
      mainState.user._id = user._id;
      mainState.user.email = user.email;
      mainState.user.membership = user.membership;
      mainState.user.verified = user.verified;

    },

    setMembership(mainState, membership) {

      mainState.membership = membership;

    },

    setEmailVerified(mainState, verified) {

      mainState.user.verified = verified;

    },

    setName(mainState, name: string) {

      mainState.concept.name = name;

    },

    setDescription(mainState, description: string) {

      mainState.concept.description = description;

    },

    share(mainState, data: { share: boolean, shareId: string }) {

      mainState.concept.share = data.share;
      mainState.concept.shareId = data.shareId;

    },

    setPermissions(mainState, data: { permissions: MePermission[], type: string }) {

      const index = mainState.user.permissions.findIndex((single) => single.type === data.type);
      const { permissions } = mainState.user;

      permissions[index] = data;

      if (index >= 0) {

        mainState.user.permissions = permissions.slice();

      } else {

        mainState.user.permissions.push(data);

      }

    },

    bedSize(mainState, size: number) {

      mainState.concept.bedSize = size;

    },

    menuState(mainState, isOpen: boolean) {

      mainState.menuIsOpen = isOpen;

    },

    menuHidden(mainState, isHidden: boolean) {

      mainState.menuIsHidden = isHidden;

    },

    menuAnimation(mainState, enabled: boolean) {

      mainState.menuAnimation = enabled;

    },

    plantsList(mainState, plantsList: any) {

      mainState.plantsList = plantsList;

    },

    setAvailableModules(mainstate, availableModules: any[]) {

      mainstate.availableModules = availableModules;

    },

  },

  actions: {
    hideOverlay() {

      this.state.overlay.active = false;

    },

    getData(store, params: any) {

      const call = new Call<CallResponse<any>>(store.getters.endpoints);
      let apiLink = '/data';

      if (params.link) {

        !params.id
          ? apiLink = `/data/${params.link}`
          : apiLink = `/data/${params.link}/${params.id}`;

      }

      return call.get('api', apiLink, {}, auth.getAuthHeader())
        .then((data: CallResponse<any>) => this.commit('setData', replace(data.r)))
        .catch((e) => {

          this.commit('setData', {});
          console.error(e);

        });

    },

    listContent(store, params: any) {

      const call = new Call();
      let apiLink: string;

      !params.site
        ? apiLink = '/data/content/list'
        : apiLink = `/data/content/list/${params.site}`;

      return call.get('api', apiLink, {}, auth.getAuthHeader())
        .then((data: CallResponse<any>) => {

          this.commit('setContent', replace(data.r));

        })
        .catch((e) => {

          console.error(e);

        });

    },

    getConfigHelp(store) {

      const call = new Call<CallResponse<any>>(store.getters.endpoints);

      return call.get('api', '/data/content/help', {}, auth.getAuthHeader())
        .then((data: CallResponse<any>) => this.commit('setConfigHelp', replace(data.r)))
        .catch((e) => {

          console.error(e);

        });

    },

    getContent(store, params: any) {

      const call = new Call<CallResponse<any>>(store.getters.endpoints);

      return call.get('api', `/data/link/${params.link}`, {}, auth.getAuthHeader())
        .then((data: CallResponse<any>) => this.commit('setData', replace(data.r)))
        .catch((e) => {

          console.error(e, params.link);
          if (params.link === 404) return;
          this.commit('set404', {});

        });

    },

    getConceptData(store, linkName: string) {

      console.log('Get concept data');

      const call = new Call<CallResponse<any>>(store.getters.endpoints);

      return call.get('api', `/data/content/${linkName}`, {}, auth.getAuthHeader())
        .then((data: CallResponse<any>) => {

          this.commit('setConceptData', replace(data.r));
          return data;

        })
        .catch((e) => {

          this.commit('setConceptData', {});
          console.error(e);

        });

    },

    getConceptDataV2(store, linkName: string) {

      console.log('Get concept data');

      const call = new Call<CallResponse<any>>(store.getters.endpoints);

      return call.get('api', `/data/content/${linkName}`, {}, auth.getAuthHeader())
        .then((res) => replace(res.r));

    },

    getShoppinglist(store, params: { id: string, reference?: string }) {

      const call = new Call<CallResponse<any>>(store.getters.endpoints);
      const method = ['', 'concept', params.id, 'shopping'];

      if (params.reference) method.push(params.reference);

      return call.get('api', method.join('/'), {}, auth.getAuthHeader())
        .then((data: CallResponse<any>) => this.commit(
          'setShoppinglist',
          {
            content: replace(data.r.data.content, data.r.data),
            data: data.r.data.data,
          },
        ))
        .catch((e) => {

          console.error(e);

        });

    },

    getShoppinglistv2(store, params: { id: string, reference?: string }) {

      const call = new Call<CallResponse<any>>(store.getters.endpoints);
      const method = ['', 'concept', params.id, 'shopping'];

      if (params.reference) method.push(params.reference);

      return call.get('api', method.join('/'), {}, auth.getAuthHeader());

    },

    getPartials(store) {

      const call = new Call<CallResponse<any>>(store.getters.endpoints);

      return call.get('api', '/data/partials', {})
        .then((data: any) => this.commit('setPartials', data.r))
        .catch((e) => {

          this.commit('setPartials', {});

          console.error(e);

        });

    },

    addToConcept(store, plant: Plant) {

      const concept = new Concept(store.state.concept);
      const call = new Call<CallResponse<any>>(store.getters.endpoints);

      return call.post('api', `/concept/${concept._id}/add`, { id: plant.id, password: concept.password }, auth.getAuthHeader())
        .then((data: CallResponse<any>) => {

          concept.add(plant);
          concept.createIndex();
          concept.refreshGroups();

          console.log(data);
          return store.commit('addPlantToConcept', concept.export());

        })
        .catch((error: any) => {

          console.log(error);

        });

    },

    removeFromConcept(store, plant: Plant) {

      const concept = new Concept(store.state.concept);
      const call = new Call<CallResponse<any>>(store.getters.endpoints);

      return call.post('api', `/concept/${concept._id}/remove`, { id: plant.id, password: concept.password }, auth.getAuthHeader())
        .then((data: CallResponse<any>) => {

          console.log(data);
          concept.remove(plant);
          concept.createIndex();
          concept.refreshGroups();

          return store.commit('removePlantFromConcept', concept.export());

        })
        .catch((error: any) => {

          console.log(error);

        });

    },

    setPartition(store, partition: Partition[]) {

      const concept = new Concept(store.state.concept);

      concept.Partition.data = partition;

      const call = new Call<CallResponse<any>>(store.getters.endpoints);

      return call.post(
        'api',
        `/concept/${concept._id}/updatePartition`,
        {
          partition,
          password: concept.password,
        },
        auth.getAuthHeader(),
      )
        .then((data: CallResponse<any>) => {

          console.log(data);
          return store.commit('setConceptPartition', concept.export().partition);

        })
        .catch((error: any) => {

          console.log(error);

        });

    },

    setFeedback(store, feedback: Feedback) {

      const concept = new Concept(store.state.concept);

      feedback.password = concept.password;
      feedback.concept = concept._id;

      const call = new Call<CallResponse<any>>(store.getters.endpoints);

      return call.post(
        'api',
        '/feedback',
        feedback,
        auth.getAuthHeader(),
      )
        .then((data: CallResponse<any>) => {

          console.log(data);
          return this.commit('setFeedback', true);

        })
        .catch((error: any) => {

          console.log(error);

        });

    },

    fullscreen(store, state: boolean) {

      if (!document) return;

      const { body } = document;

      state
        ? body.classList.add('fullscreen')
        : body.classList.remove('fullscreen');

      store.commit('setFullscreen', state);

    },

    getPermissions(store, data?: { company: string, project: string }) {

      const call = new Call<CallResponse<any>>(store.getters.endpoints);

      console.log('GET PERMISSIONS');

      // if (!store.state.user._id) return;

      const url = ['me', 'permissions'];
      let type = 'user';

      if (data) {

        if (data.company) {

          url.push(data.company);
          type = 'company';

        }

        if (data.project) {

          url.push(data.project);
          type = 'project';

        }

      }

      return call.get('api', `/${url.join('/')}`, {}, auth.getAuthHeader())
        .then((data: any) => this.commit('setPermissions', { type, permissions: data.r }))
        .catch((e) => {

          console.error(e);
          throw new Error(e);

        });

    },

    getMe(store) {

      const call = new Call<CallResponse<any>>(store.getters.endpoints);

      return call.get('api', '/me/data', {}, auth.getAuthHeader())
        .then((data: any) => {

          console.log(data);
          this.commit('setUser', data.r);

          if (data.r.locked === 'EMAIL_VERIFICATION') {

            this.commit('lock', 'me-verify');

          }

          return data.r;

        })
        .catch((e) => {

          console.error(e);
          throw new Error(e);

        });

    },

    getMembership(store) {

      const call = new Call<CallResponse<any>>(store.getters.endpoints);

      return call.get('api', '/membership', {}, auth.getAuthHeader())
        .then((data: any) => {

          console.log(data);
          this.commit('setMembership', data.r);

          return data.r;

        });

    },

    checkEmailVerified(store) {

      const call = new Call<CallResponse<any>>(store.getters.endpoints);

      return call.head('api', '/me/emailVerified', {}, auth.getAuthHeader())
        .then((response) => {

          if (response.status !== 200) {

            throw new Error('no verified');

          }

          return true;

        });

    },

    verify(store, code: number) {

      const call = new Call<CallResponse<any>>(store.getters.endpoints);

      return call.post('api', '/me/verify', { code }, auth.getAuthHeader());

    },

    saveMe(store, data: Me) {

      const call = new Call<CallResponse<any>>(store.getters.endpoints);

      return call.post('api', '/me/data', data, auth.getAuthHeader())
        .then((data: any) => {

          this.commit('setUser', data.r);

          if (store.state.lock) this.commit('unlock');

          return data.r;

        })
        .catch((e) => {

          console.log(e);
          throw new Error(e);

        });

    },

    setName(store, data: any) {

      const call = new Call<CallResponse<any>>(store.getters.endpoints);

      return call.post('api', `/concept/${data.concept}/name`, data, auth.getAuthHeader())
        .then(() => this.commit('setName', data.name));

    },

    setDescription(store, data: any) {

      const call = new Call<CallResponse<any>>(store.getters.endpoints);

      return call.post('api', `/concept/${data.concept}/description`, data, auth.getAuthHeader())
        .then(() => this.commit('setDescription', data.description));

    },

    setBedSize(store, data: any) {

      const call = new Call<CallResponse<any>>(store.getters.endpoints);

      return call.post('api', `/concept/${data.concept}/bedSize`, data, auth.getAuthHeader())
        .then(() => this.commit('bedSize', data.bedSize));

    },

    getConcept(store: any, conceptId: string) {

      const call = new Call<CallResponse<any>>(store.getters.endpoints);

      return call.get('api', `/concept/${conceptId}`, {}, auth.getAuthHeader())
        .then((response: CallResponse<any>) => {

          if (response.status === 202) {

            return {
              concept: false,
              redirect: true,
              shareId: response.r,
            };

          }

          const { data } = response.r;
          let setPartition: boolean;
          const concept = new Concept(data);

          if (data?.plants?.length > 0) {

            if (data.partition) {

              setPartition = data.partition.length === 0;

            } else {

              setPartition = true;

            }

          }

          if (data.plants?.length > 0) {

            concept.refreshGroups();

          }

          store.commit('setConcept', concept.export());

          if (setPartition) {

            return store.dispatch('setPartition', concept.Partition.data);

          }

          return concept;

        });

    },

    copyConcept(store: any, conceptId: string) {

      const call = new Call<CallResponse<any>>(store.getters.endpoints);

      return call.post('api', `/concept/${conceptId}/copy`, {}, auth.getAuthHeader());

    },

    listPlants(store, settings: {query: Record<string, unknown>, site?: number}) {

      let url = '/plants';
      const call = new Call<CallResponse<any>>(store.getters.endpoints);

      if (settings.site) url += `/${settings.site}`;

      console.log(settings);

      return call
        .get('api', url, { ...settings.query, sort: 'name', order: 1 }, auth.getAuthHeader());

    },

    deleteConcept(store, conceptId: string) {

      const call = new Call<CallResponse<any>>(store.getters.endpoints);

      return call.delete('api', `/concept/${conceptId}`, {}, auth.getAuthHeader());

    },

    toggleShare(store, data: { active: boolean, conceptId: string }) {

      const call = new Call<CallResponse<any>>(store.getters.endpoints);

      return call.post('api', `/concept/${data.conceptId}/share`, { share: data.active }, auth.getAuthHeader())
        .then((response) => store.commit('share', response.r));

    },

    activateMembership(store, key: string) {

      const call = new Call<CallResponse<any>>(store.getters.endpoints);

      return call.post('api', '/me/activate', { key }, auth.getAuthHeader());

    },

    health(store) {

      const call = new Call<CallResponse<any>>(store.getters.endpoints);

      return call.get('api', '/health', {}, auth.getAuthHeader());

    },

  },
});

export {
  state,
};

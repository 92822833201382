import vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import inview from '@libs/lila-inview';
import Dom from '@libs/lila-dom';
import { Store } from 'vuex';
import { Dictionary } from 'vue-router/types/router';
import { Route } from 'vue-router';
import MainStoreState from '@interfaces/MainStoreState.interface';
import auth from './lila-auth';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
  'beforeRouteLeave',
  'startLoad',
]);
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
@Component({
  inheritAttrs: false,
})
abstract class ExtComponent extends vue {

  public state = '';

  public view = '';

  public fullscreen = false;

  public DOM: Dom;

  name: string;

  abstract componentName: string | string[];

  public animation = '';

  /**
   * disables the animation for the next routing
  */
  public animationDisabled = false;

  public triggerScroll = true;

  @Prop({ type: Array, default: () => [] }) variant: string[];

  leaveGuard: (to: Route, from: Route, next: () => void, component: this) => void;

  @Prop(String) id: string;

  $store: Store<MainStoreState>;

  getContent?(): any;

  constructor() {

    super();
    this.DOM = new Dom(this.$store);

  }

  /**
   * add a eventlistener to this element
   * calls the [[Inview.check]] function
   *
   * @memberof ExtComponent
   */
  checkInview(): void {

    window.addEventListener('scrolled', () => {

      inview.check(this);

    });

  }

  // eslint-disable-next-line class-methods-use-this
  attachScrollCheck(elements: NodeListOf<Element>): void {

    elements.forEach((element) => {

      inview.addScrollListener(element);

    });

  }

  setState(type: string): void {

    this.state = type;

  }

  get realHeight(): number {

    return this.$store.state.realHeight;

  }

  set realHeight(realHeight: number) {

    this.$store.commit('setHeight', realHeight);

  }

  get heightStyle(): {[key: string]: string} {

    return {
      '--height': `${this.$store.state.realHeight - 40}px`,
    };

  }

  get contentHidden() {

    return this.$store.state.Settings.settings[this.name] === 'hidden';

  }

  get isShare(): boolean {

    return !!this.$route.meta?.share;

  }

  get conceptsAvailable(): boolean {

    return this.isUser && this.$store.state.membership.premium && (this.$store.state.membership.createdConcepts < this.$store.state.membership.maxConcepts || this.$store.state.membership.maxConcepts === 'unlimited');

  }

  updateContent<T>(): T {

    if (this.getContent) {

      return this.contentHidden
        ? this.$store.commit('setConceptData', {})
        : this.getContent();

    }

    return null;

  }

  get isUser(): boolean {

    return auth.isUser(this.$store.state.user);

  }

  @Watch('$route')
  routeChange(): void {

    this.animationDisabled = false;
    this.animation = '';

  }

  get animationTarget() {

    return this.$store.state.Navigation.targetComponent;

  }

  get loadingState() {

    return this.$store.state.Navigation.status;

  }

  /**
 * if animation is 'out' - we are leaving this component - set the class for fadeout animation
 * if animation is 'in' - we are entering this component - and loadingState is resolving, set class for fadein
 *
 * @readonly
 * @memberof ExtComponent
 */
  get navigationAnimation() {

    if (this.$store.state.Navigation.contentUpdate) return {};

    return {
      started: this.animation === 'out',
      resolving: this.animation === 'in' && ['resolving', 'loading'].includes(this.loadingState),
    };

  }

  get contentAnimation() {

    if (!this.$store.state.Navigation.contentUpdate) return { };

    return {
      started: this.loadingState !== 'done',
      resolving: this.loadingState === 'resolving',
    };

  }

  get universalAnimation() {

    return {
      started: this.loadingState !== 'done',
      resolving: this.loadingState === 'resolving',
    };

  }

  /**
   * checks if the targetComponentname is euqal this componentname or the targetComponentname is in this componentname array
   * sidescreen is always fade in
   * if so, set animation to fade in
   *
   * @memberof ExtComponent
   */
  beforeMount() {

    if (this.animationDisabled) {

      this.animationDisabled = false;
      return;

    }

    if (Array.isArray(this.componentName)) {

      if (this.componentName.includes(this.$store.state.Navigation.targetComponent)) this.fadeIn();

    } else if ((this.componentName && this.$store.state.Navigation.targetComponent === this.componentName)) {

      this.fadeIn();

    }

  }

  /**
   * sets the animation to fadeOut
   *
   * @param {Route} to
   * @param {Route} from
   * @param {() => {}} next
   * @memberof ExtComponent
   */
  beforeRouteLeave(to: Route, from: Route, next: (stop) => void): void {

    if (typeof this.leaveGuard === 'function') {

      this.leaveGuard(to, from, (navigate = true) => {

        if (navigate) {

          this.routeLeave(next);

        } else {

          next(false);

        }

      }, this);

    } else {

      this.routeLeave(next);

    }

  }

  async routeLeave(next: (stop?) => void): Promise<void> {


    if (this.animationDisabled) {

      next();
      return;

    }

    // wait 500ms until the menu is closed to start the navigation
    if (this.$store.state.menuIsOpen) {

      await new Promise<void>((resolve) => {

        setTimeout(() => resolve(), 500);

      });

    }


    this.animation = 'out';
    next();

  }

  fadeOut(): void {

    this.animation = 'out';

  }

  fadeIn(): void {

    this.animation = 'in';

  }

  /** @deprecated */
  asyncData?(
    params: Dictionary<string>,
    query: Dictionary<string|string[]>,
    store: Store<MainStoreState>,
    to: Route
  ): Promise<unknown>;

  preloadDataPre?(
    params: Dictionary<string>,
    query: Dictionary<string|string[]>,
    store: Store<MainStoreState>,
    to: Route
  ): Promise<unknown>;

  preloadDataPost?(
    preloadedData: unknown,
    params: Dictionary<string>,
    query: Dictionary<string|string[]>,
    store: Store<MainStoreState>,
    to: Route
  ): Promise<unknown>;

  preloadDataError?(
    preloadedData: unknown,
    params: Dictionary<string>,
    query: Dictionary<string|string[]>,
    store: Store<MainStoreState>,
    to: Route
  ): Promise<unknown>;

}

export {
  Component,
  ExtComponent,
  Prop,
  Watch,
  inview,
  vue,
};

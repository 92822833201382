import VueRouter, { RouteConfig } from 'vue-router';

export default (routes: RouteConfig[]): VueRouter => new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {

    if (to.hash) {

      return { selector: to.hash };

    } if (savedPosition) {

      return savedPosition;

    }
    return { x: 0, y: 0 };

  },
});

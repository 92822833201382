import vue from 'vue';
import vueRouter from 'vue-router';
import PortalVue from 'portal-vue';

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import '../source/less/base_bootstrap.less';

import Resize from '@libs/lila-resize';
import { Call } from '@libs/lila-call';

import '@mixins/leadingZero';
import '@mixins/toFixed';
import '@mixins/scope';
import '@mixins/moment';
import translation from '@mixins/translation';
import translationsDE from 'translations/de';

import APPComponent from '@partials/appcomponent.partial.vue';

import store, { state } from '@store/main.store';
import auth from '@libs/lila-auth';

import log from 'loglevel';
import loadComponents from '@mixins/loadComponents';
import router from '@mixins/router';
import initialScroll from '@mixins/scroll';
import mount from '@mixins/mount';
import CallResponse from '@interfaces/lila-call.interface';
import Components from '@libs/Components';
import routes from '../routes';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const modules = require('./modules');

translation.select('de');

let ENVIRONMENT: string;

if (window.location.toString().match(/editor-child|editor-plants$/i)) {

  ENVIRONMENT = 'editor';

} else if (window.location.toString().match(/local:9001/i)) {

  ENVIRONMENT = 'dev';

} else {

  ENVIRONMENT = 'live';

}

// eslint-disable-next-line no-unused-expressions
ENVIRONMENT === 'editor' || ENVIRONMENT === 'dev'
  ? log.enableAll()
  : log.setLevel('warn');

const _window: any = window;

vue.use(vueRouter);
vue.use(PortalVue);

const appObject: any = {

  render: (h: any) => h(APPComponent),

  mounted: initialScroll,
  router: router(routes),
  store,
};

/**
 * check if a state is stored and use it
 */
if (_window.__INITIAL_STATE__) {

  store.replaceState(_window.__INITIAL_STATE__);

}

window.addEventListener('media', () => {

  store.commit('setMedia', Resize.media);

});

store.commit('setMedia', Resize.media);

window.addEventListener('resized', () => {

  store.commit('setHeight', Resize.realHeight);

});

store.commit('setHeight', Resize.realHeight);

vue.directive('attributes', (el: Element, attributes: any) => {

  if (!attributes) return;
  if (!attributes.value) return;

  attributes.value.forEach((attribute: string) => {

    el.setAttribute(attribute, '');

  });

});

Components.add(modules, 'module', 'lila');

store.commit('setAvailableModules', Components.getAvailableModules(modules));

loadComponents(require.context('../partials', true, /[a-z]\w+\.partial\.(ts|vue)$/, 'lazy'), 'partials');
loadComponents(require.context('../modules', true, /[a-z]\w+\.module\.(ts|vue)$/, 'lazy'), 'modules');

log.info(`%c[GFM]%cRUNNING IN ${ENVIRONMENT} MODE`, 'background: #00505a; color: #CCC;', 'padding: 10px');

log.info('%c[GFM]%cAPP CREATED', 'background: #00505a; color: #CCC;', 'padding: 10px');

/**
 * if we are in a editor, post settings and listen for the anwser message
 */
if (ENVIRONMENT === 'editor') {

  window.parent.postMessage('studio-design-settings', '*');

  window.addEventListener(
    'message',
    (message) => {

      if (message.data.type === 'studio-settings') {

        const call = new Call<CallResponse<any>>();

        call.get('', 'translations/de.json')
          .then((data) => {

            store.commit('setTranslation', data.r);
            translation.add(data.r, 'de');

            store.commit('setSettings', message.data.data);

            return true;

          })
          .catch((e) => {

            store.dispatch('initError');
            log.error(e);

          });

        mount(appObject);

      }

    },
    false,
  );

}

/** if we are in live mode, load the ssr state and mount the app */
if (ENVIRONMENT === 'live' || ENVIRONMENT === 'dev') {

  // ssr(store);
  const call = new Call<CallResponse<any>>(store.getters.endpoints);

  call.get('', 'settings/settings.json', {})
    .then((settings) => {

      store.commit('setSettings', settings.r);
      store.commit('setTranslation', translationsDE);
      translation.add(translationsDE, 'de');
      return store.dispatch('health');

    })
    .then(() => store.dispatch('getPartials'))
    .then(() => auth.init(state.settings.dev, state.settings.auth0))
    .then(() => mount(appObject))
    .catch((e) => {

      store.dispatch('initError');
      log.error(e);

    });

}















import {
  ExtComponent, Component, Watch,
} from '@libs/lila-component';
import Auth from '@libs/lila-auth';

@Component
export default class appComponentPartial extends ExtComponent {

  componentName = 'app-component-partial';

  @Watch('menuIsOpen')
  menuWatcher() {

    const body = document.querySelector('body');

    console.log(this.menuIsOpen);

    if (this.menuIsOpen) {

      body.classList.add('menuIsOpen');

    } else {

      body.classList.remove('menuIsOpen');

    }

  }

  get heightStyle() {

    return {
      '--height': `${this.realHeight}px`,
    };

  }

  get menuIsOpen() {

    return this.$store.state.menuIsOpen;

  }

  mounted() {

    this.$root.$on('login', this.login);
    this.$root.$on('logout', this.logout);
    this.$root.$on('signup', this.signup);

  }

  // eslint-disable-next-line class-methods-use-this
  logout() {

    Auth.logout();

  }

  // eslint-disable-next-line class-methods-use-this
  login() {

    Auth.authorize();

  }

  // eslint-disable-next-line class-methods-use-this
  signup() {

    Auth.register();

  }

}

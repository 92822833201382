




















import MainStoreState from '@base/@types/MainStoreState';
import auth from '@libs/lila-auth';
import {
  ExtComponent, Component,
} from '@libs/lila-component';
import hardCopy from '@mixins/hardCopy';
import prepareContent, { generateDataWithContent, distributeGenericData } from '@mixins/prepareContent';
import log from 'loglevel';
import { Route } from 'vue-router';
import { Store } from 'vuex';

@Component
export default class ChallengeScreen extends ExtComponent {

  signupIndicator = false;

  get choices() {

    return this.$store.state.Challenge.choices;

  }

  get content() {

    let content = hardCopy(this.$store.state.Challenge.content);

    if (content.genericData) {

      const genericDataWithContent = generateDataWithContent(content.genericData);

      distributeGenericData(content.modules, genericDataWithContent);

    }


    content = prepareContent(content);

    if (content.tags?.includes('concept')) {

      /**
       * push the concept module
      */
      content.modules.unshift({
        type: 'concept-module',
        concept: this.concept,
        mode: 'share',
        position: 'content',
      });

    }

    return content;

  }

  get enrollChallenge() {

    return this.$store.state.partials?.enrollChallenge;

  }

  get enrolled() {

    return this.$store.state.Challenge.enrolled;

  }

  get challenge() {

    return this.$store.state.Challenge.challenge;

  }

  get concept() {

    return this.$store.state.Shared.concept;

  }

  get error() {

    if (this.$store.state.lock) {

      return {
        error: true,
        status: 403,
      };

    }

    return this.$store.state.Challenge.content?.error ? this.$store.state.Challenge.content : false;

  }

  check() {

    return auth.getNewToken();

  }

  mounted() {


    if (this.$store.state.user.verified && !this.$store.state.Challenge.enrolled && this.$route.params.signup) {

      this.signup();

    }

  }

  signup() {

    this.signupIndicator = true;

    this.$store.dispatch('Challenge/signup', this.$route.params.challenge)
      .then(() => {

        this.$store.commit('Challenge/status', true);

        const params = this.$route.params;

        if (params.signup) {

          delete params.signup;

          this.$router.replace({ name: this.$route.name, params });

        }

        this.signupIndicator = false;

      })
      .catch((e) => {

        this.signupIndicator = false;
        log.error(e.status, e.r);

      });

  }

  emailVerified(isVerified: boolean) {

    /**
     * if the error module emit the isVerified event and the user is verfified
     * and not enrollen in the challenge, the user gets signed up
    */
    if (isVerified && !this.$store.state.Challenge.enrolled) {

      this.signup();

    }

  }


  asyncData(to: Route, store?: Store<MainStoreState>): Promise<any> {

    return store.dispatch('Challenge/getStatus', { challenge: to.params.challenge, id: to.params.id })
      .then(() => store.dispatch('Challenge/getContent', { challenge: to.params.challenge, id: to.params.id }))
      .then(() => {

        if (store.state.Challenge.challenge?.shareId) {

          return store.dispatch('Shared/getConcept', store.state.Challenge.challenge.shareId);

        }

        return true;

      });

  }

}

